const contactForm = () => {
  $(document).on('submit', '#cf_section_form', function(e) {
    e.preventDefault();

    $(this).addClass('submitting');
    $('.cf-section__form-submit-overlay').show();

    const data = $(this).serialize();

    $.ajax({
      type: 'POST',
      dataType: 'html',
      url: main__js_vars.ajaxurl,
      data: data + '&action=ajax_contact_form_submit',
      beforeSend: function() {
        const minHeight = $('.cf-section__form-container').height();
        $('.cf-section__form-container').css('height', minHeight);
      },
      success: function () {
        let tl = gsap.timeline();
        tl.to('.contact-form__form', { autoAlpha: 0, duration: 0.6 });
        tl.to('.contact-form__form', { height: 0, duration: 0.1 });
        tl.to('.contact-form__form-thank-you', { autoAlpha: 1, duration: 0.6, delay: 0.2 });
        tl.to('.contact-form__form-container', { height: 'auto', duration: 0.4, delay: 0.1 })
      },
      error : function (jqXHR, textStatus, errorThrown) {
        $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
        console.log(jqXHR);
      },
    });
  });
};