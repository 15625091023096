const animations = () => {
    textFadeInUp();
    staggeredFadeInUp();
    imageReaveal();

    if ( $('.whats-on__content-elems').length && $(window).width() > 991 ) {
        fixShareIconsOnScroll();
    }
};

const textFadeInUp = () => { 
    $(window).on('load resize scroll', function(e) {
        $('.a__text-fade-in-up').each(function(e) {
            const delay = ( $(this).data('animation-delay') ) ? $(this).data('animation-delay') : 0;

            if ( inView($(this), 50) ) {
                let tl;
                tl = gsap.timeline({delay: delay});
                tl.to($(this), { y: 0, opacity: 1, ease: Power1.easeOut, duration: 1 });
            }
        });
    });
};

const staggeredFadeInUp = () => {
    $(window).on('load resize scroll', function(e) {
        $('.a__staggered-fade-in-up').each(function(e) {
            const elem = $(this).find('.a__staggered-fade-in-up-elem');
            const inViewElem = ( $(window).width() > 767 ) ? $(this) : elem;
            const stagger = ( $(window).width() > 767 ) ? 0.4 : 0;

            if ( inView(inViewElem, 50) ) {
                let tl;
                tl = gsap.timeline();
                tl.to( elem, {
                    opacity: 1,
                    y: 0,
                    ease: Power1.easeOut,
                    duration: 1,
                    stagger: stagger }
                );
            }
        });
    });    
};

const imageReaveal = () => {
    gsap.utils.toArray('.a__image-reveal').forEach(section => {
        const maskElem = section.querySelector('.u__image-mask');
        const sliderElem = section.querySelector('.c__image-slider');
        sliderX = ( maskElem.classList.contains('.u__image-mask') ) ? -1 : 1;

        let tl;
        tl = gsap.timeline({
            scrollTrigger: section
        });
        tl.fromTo(maskElem, 0.85, { scaleX: 1 }, { scaleX: 0, ease: Power2.easeOut }, 'start');
        tl.fromTo(sliderElem, 0.85, { 
            x: 50 * sliderX,
            scaleX: 1.15,
            transformOrigin: '0 0'
        }, { 
            x: 0,
            scaleX: 1,
            ease: Power2.easeOut
        }, 'start');
    });   
};

const fixShareIconsOnScroll = () => {
    const fixedHeaderHeight = $('.c-header').height();
    const extraOffset = $('.whats-on__date').css('margin-bottom');
    const offset = '-=' + (parseInt(extraOffset.replace('px', '')) + fixedHeaderHeight);
    const end = '+=' + ($('.whats-on__content-elems').outerHeight(true) - $('.whats-on__share-container').height());

    console.log(end);

    ScrollTrigger.create({
        trigger: '.whats-on__share-container',
        start: offset,
        end: end,
        pin: '.whats-on__share-container',
        pinSpacing: false
    });
}