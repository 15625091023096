const sevenrooms = () => {
    /*
  Booking button ID
  */
    $('.js__booking-btn').click(function (e) {
        e.preventDefault();
        $('#sr_booking_button')[0].click();
    });

    $('a').click(function (event) {
        if ($(this).attr('href') == 'www.sevenrooms.com/reservations/florattica') {
            event.preventDefault();
            $('#bookingbutton')[0].click();
        }
    });

    /*
    SevenRooms
    */

    if(typeof SevenroomsWidget !== 'undefined'){
        SevenroomsWidget.init({
            venueId: "florattica",
            triggerId: "sr_booking_button", // id of the dom element that will trigger this widget
            type: "reservations", // either 'reservations' or 'waitlist' or 'events'
            styleButton: false, // true if you are using the SevenRooms button
            clientToken: "" //(Optional) Pass the api generated clientTokenId here
        });
    }
   
}