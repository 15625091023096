const popup = () => {
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    if ($('.popup').length) {
        setTimeout(function () {
            $('.popup').addClass('open');
        }, 1500);
    }

    //Close popup
    const closePopupLinksOnPage = document.querySelectorAll('.js__popup-close'); //Get all popup links on page
    
    if(closePopupLinksOnPage.length > 0){ //Check if there are any links first
        closePopupLinksOnPage.forEach((closePopupLink) => { //Loop through each and give it a onclick event listener
            closePopupLink.addEventListener('click', (event) => {
                event.preventDefault(); //Stop the link firing normally !
                setCookie('popup', 'popped', 1);
                if ($('.popup').hasClass('open')) {
                    $('.popup').removeClass('open');
                }
            });
        });
    }
}