const mailchimp = () => {
	

	// Footer Newsletter Signup
	const openNewsletterLinksOnPage = document.querySelectorAll('.js__newsletter-signup'); //Get all popup links on page
	if(openNewsletterLinksOnPage.length > 0){ //Check if there are any links first
	    openNewsletterLinksOnPage.forEach((openLink) => { //Loop through each and give it a onclick event listener
	        openLink.addEventListener('click', (event) => {
	            event.preventDefault();
				$('.signup-section').addClass('open');
	        });
	    });
	}

	$(document).on('click tap touchstart', '.signup-section__close', function (e) {
		e.preventDefault();
		$('.signup-section').removeClass('open');
	});


	// Landing page content signup
	$(document).on('submit', '.js-signup-form', function(e) {
		e.preventDefault();

		const form = $(this);
		const email = form.find('input[type=email]').val();
		const message = form.find('.c-signup-form__message');

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			data: {
				action: 'mailchimp',
				email: email,
			},
			beforeSend: function () {
				const minHeight = form.height();

				console.log(minHeight);

				form.css('minHeight', minHeight);
				$('.js-signup-form-elem').addClass('hidden');
				$('.c-signup-form__loader-container').addClass('showing');
			},
			success: function (data) {
				data = JSON.parse(data);

				$('.js-signup-form-elem').hide();
				$('.c-signup-form__loader-container').removeClass('showing');

				message.html('<p>' + data.message + '</p>');
				message.show();
				message.addClass('showing');
			},
			error: function (data) {
				data = JSON.parse(data);
				$('.js-signup-form-elem').hide();
				$('.c-signup-form__loader-container').removeClass('showing');

				message.html('<p>' + data.message + '</p>');
				message.show();
				message.addClass('showing');
			}
		});

		return false;
	});

	let loader = '<div class="loader"><div class="ball-clip-rotate"><div></div></div></div>';
	$(document).on('click tap touchstart', '#signup-button', function (e) {
		e.preventDefault();
		let email = $('#email').val();
		let message = $('.signup-section #message');

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			data: {
				action: 'mailchimp',
				email: email,
			},
			beforeSend: function () {
				message.removeClass('success');
				message.removeClass('error');
				$('#loader').html(loader);
			},
			success: function (data) {
				data = JSON.parse(data);
				$('#loader .loader').remove();
				message.addClass('success');
				message.html('<p>' + data.message + '</p>');
			},
			error: function (data) {
				data = JSON.parse(data);
				$('#loader .loader').remove();
				message.addClass('error');
				message.html('<p>' + data.message + '</p>');
			}
		});
		return false;
	});
}