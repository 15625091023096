const gallery = () => {
    $(document).on('click', '.js__gallery-filter-trigger', function(e) {
        e.preventDefault();

        if ( !$(this).hasClass('active') ) {
            setGalleryFilters( $(this) );
        }
    });

    $(document).on('click', '.c__gallery-filters-clear-link', function(e) {
        e.preventDefault();

        clearGalleryFilters( $(this) );
    });
    
    $('#gallery_filters_dropdown').on('change', function(e) {
        e.preventDefault();
        
        setGalleryFilters( $(this), 'dropdown' );
    });

    $(document).on('click', '.js__gallery-load-more', function(e) {
        e.preventDefault();

        loadMoreGalleryItems( $(this) );
    });

    lightboxInit();
};

const setGalleryFilters = (elem, type = 'click') => {
    $('.js__gallery-filter-trigger').removeClass('active');
    elem.addClass('active');
    $('.c__gallery-filters-clear-link').addClass('clickable');

    const galleryModule = elem.closest('.gallery');
    const moduleID = galleryModule.data('id');
    const moduleIndex = galleryModule.data('index');
    const filter = (type == 'dropdown') ? elem.val() : elem.data('filter');

    getGalleryItems(moduleID, moduleIndex, filter, 0);
};

const clearGalleryFilters = (elem, type = 'click') => {
    $('.js__gallery-filter-trigger').removeClass('active');
    elem.removeClass('clickable');

    const galleryModule = elem.closest('.gallery');
    const moduleID = galleryModule.data('id');
    const moduleIndex = galleryModule.data('index');
    const filter = (type == 'dropdown') ? elem.val() : elem.data('filter');

    getGalleryItems(moduleID, moduleIndex, filter, 0);
};

const loadMoreGalleryItems = (elem) => {
    const galleryModule = elem.closest('.gallery');
    const moduleID = galleryModule.data('id');
    const moduleIndex = galleryModule.data('index');
    const filter = ( $('.js__gallery-filter-trigger.active').length ) ? $('.js__gallery-filter-trigger.active').data('filter') : 'all';
    const offset = $('.c__gallery-item').length;

    getGalleryItems(moduleID, moduleIndex, filter, offset, true);
};

const getGalleryItems = (moduleID, moduleIndex, filter, offset = 0, loadMore = false) => {
    $.ajax({
        type: 'POST',
        dataType: 'html',
        url: main__js_vars.ajaxurl,
        data: {
            'module_ID': moduleID,
            'module_index': moduleIndex,
            'filter': filter,
            'offset': offset,
            'action': 'ajax_get_gallery_items'
        },
        beforeSend : function () {
            if ( loadMore ) {
                $('.gallery__load-more-button').text('Loading...');
            } else {
                $('.gallery__items-container').css('min-height', $('.gallery__items-container').outerHeight() + 'px');
                $('.c__gallery-item').addClass('c__gallery-item--hidden');
            }
        },
        success: function (data) {
            const returnedData = JSON.parse(data);

            if ( loadMore ) {
                $('.gallery__items-container').append(returnedData.gallery_items);

                setTimeout(function() {
                    $('.c__gallery-item').removeClass('c__gallery-item--hidden');
                }, 50);

                $('.gallery__load-more-button').text('Load more');
            } else {
                $('.gallery__items-container').html(returnedData.gallery_items);
                
                setTimeout(function() {
                    $('.c__gallery-item').removeClass('c__gallery-item--hidden');
                }, 50);
    
                $('.gallery__items-container').removeAttr('style');
            }

            if ( $('.c__gallery-item').length === returnedData.total_gallery_items ) {
                $('.gallery__load-more-container').fadeOut();
            } else {
                $('.gallery__load-more-container').fadeIn();
            }

            lightboxInit();
        },
        error : function (jqXHR, textStatus, errorThrown) {
            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
            console.log(jqXHR);
        },
    });
}

const lightboxInit = () => {
    if ( $(window).width() > 767 && $('.js__gallery-trigger').length ) {
        const galleryImages = GLightbox({
            selector: 'js__gallery-trigger',
            touchNavigation: true,
            zoomable: false,
            svg: {
                close: '<svg xmlns="http://www.w3.org/2000/svg" width="33.386" height="33.386" viewBox="0 0 33.386 33.386"><g transform="translate(-1337.316 -78.316)"><g transform="translate(1337.67 78.67)"><path d="M0,0V46.215" transform="translate(0 0) rotate(-45)" fill="none" stroke="#f2dfc9" stroke-width="1"/><path d="M0,0V46.215" transform="translate(0 32.679) rotate(-135)" fill="none" stroke="#f2dfc9" stroke-width="1"/></g></g></svg>',
                next: '<svg xmlns="http://www.w3.org/2000/svg" width="51.976" height="17.257" viewBox="0 0 51.976 17.257"><g transform="translate(-749 -442.113)"><path d="M-1300,783.07h51.268" transform="translate(2049 -332.329)" fill="none" stroke="#f2dfc9" stroke-width="1"/><path d="M-1107.66,814.945l8.275,8.275-8.275,8.275" transform="translate(1899.654 -372.479)" fill="none" stroke="#f2dfc9" stroke-width="1"/></g></svg>',
                prev: '<svg xmlns="http://www.w3.org/2000/svg" width="51.976" height="17.257" viewBox="0 0 51.976 17.257"><g transform="translate(800.976 459.37) rotate(180)"><path d="M-1300,783.07h51.268" transform="translate(2049 -332.329)" fill="none" stroke="#f2dfc9" stroke-width="1"/><path d="M-1107.66,814.945l8.275,8.275-8.275,8.275" transform="translate(1899.654 -372.479)" fill="none" stroke="#f2dfc9" stroke-width="1"/></g></svg>'
              }        		
        });
    }
}