const menus = () => {
    if ( $('.menus').length ) {
        const filtersTop = $('.menus__desktop-filter').offset().top;
    
        $(window).on('load resize scroll', function(e) {
            if ( $(window).width() > 991 ) {
                fixMenuFiltersDesktop(filtersTop);
                updateActiveMenuFilterOnScroll();
            }
        });
    }

    $(document).on('click', '.menus__desktop-filter-type.active .js__change-menu', function(e) {
        e.preventDefault();

        scrollToMenu($(this));
    });
    $(document).on('click', '.menus__mobile-menu .js__change-menu', function(e) {
        e.preventDefault();

        scrollToMenu($(this), true);
    });

    $(document).on('click', '.menus__desktop-filter-type:not(.active) .js__change-menu', function(e) {
        e.preventDefault();
        $('.menus__desktop-filter-type').removeClass('active');
        $(this).addClass('active');
        getMenus($(this));
    });

    $(document).on('click', '.menus__mobile-filter-type.js__change-menu-type', function(e) {
        e.preventDefault();

        getMenus($(this), true);
    });
};

const fixMenuFiltersDesktop = (filtersTop) => {
    const scrollTop = $(window).scrollTop();
    const headerHeight = $('.c-header').height();
    const sectionHeight = $('.menus').outerHeight();
    const sectionBottom = ($('.menus').offset().top + sectionHeight);
  
    if ( (scrollTop + headerHeight) >= filtersTop && (scrollTop + $(window).height()) < sectionBottom ) {
        $('.menus__desktop-filter').addClass('fixed');
        $('.menus__desktop-filter').css('top', headerHeight + (scrollTop - filtersTop));
    } else {
        $('.menus__desktop-filter').removeClass('fixed');
    }    
};

const updateActiveMenuFilterOnScroll = () => {
    $('.menu').each(function() {
        const windowBottom = $(window).scrollTop() + $(window).height();
        const elemBottom = $(this).offset().top + $(this).height();
        const menuID = $(this).data('menu-id');
        const elem = $('.menus__desktop-menu .menus__menu-link[data-menu-id="'+menuID+'"]');

        if ( inView($(this)) && windowBottom < elemBottom ) {
            $('.menus__menu-link, .menus__desktop-menus-arrow').removeClass('active');
            elem.addClass('active');
            elem.siblings('.menus__desktop-menus-arrow').addClass('active');             
        }
    });    
};

const scrollToMenu = (elem, mobile = false) => {
    const menu = elem.data('menu-id');
    const offset = $('.c-header').height();
    $('html, body').animate({
        scrollTop: $('.menu[data-menu-id="'+menu+'"]').offset().top - offset
    }, 750);    
    
    if ( mobile ) {
        $('.menus__menu-link, .menus__mobile-menus-arrow').removeClass('active');
        elem.addClass('active');
        elem.siblings('.menus__mobile-menus-arrow').addClass('active');
    } else {
        $('.menus__menu-link, .menus__desktop-menus-arrow').removeClass('active');
        elem.addClass('active');
        elem.siblings('.menus__desktop-menus-arrow').addClass('active');
    }
};

const getMenus = (elem, mobile = false) => {
    const menuType = elem.data('menu-type');
    $.ajax({
        type: 'POST',
        dataType: 'html',
        url: main__js_vars.ajaxurl,
        data: {
            'menu_type' : menuType,
            'mobile': mobile,
            'action': 'ajax_change_menu_type'
        },
        beforeSend : function () {
            if ( mobile ) {
                const menusFilterHeight = $('.menus__mobile-menus').outerHeight();
                
                $('.menus__mobile-menus').css('min-height', menusFilterHeight + 'px');
                $('.menus__mobile-menus-inner').fadeOut();
            } else {
                const menuContainerHeight = $('.menus__menus-container').outerHeight();
    
                $('.menus__menus-container').css('min-height', menuContainerHeight + 'px');
                $('.menus__menus').fadeOut();
            }
        },
        success: function (data) {
            const returnedData = JSON.parse(data);

            $('.menus__menus-container').html(returnedData.menus);

            if ( mobile ) {
                $('.menus__mobile-menus').html(returnedData.menu_filters);
            }

            // if ( elem.data('menu-id') ) {
            //     scrollToMenu(elem, mobile);
            // }

            if ( !mobile ) {
                $('.menus__desktop-menus').removeClass('active');
                elem.closest('.menus__desktop-menus').addClass('active');
            } else {
                $('.menus__mobile-filter-type').removeClass('active');
                elem.addClass('active');
            }

            setTimeout(function(e) {
                $('.menus__menus-container').fadeIn();
                $('.menus__menus-container').removeAttr('style');

                if ( mobile ) {
                    $('.menus__mobile-menus-inner').fadeIn();
                    $('.menus__mobile-menus').removeAttr('style');
                }
            }, 400);
        },
        error : function (jqXHR, textStatus, errorThrown) {
            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
            console.log(jqXHR);
        },
    });
}