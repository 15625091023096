const header = () => {
    ScrollTrigger.create({
        trigger: '.c-header__nav-row',
        start: 'top top',
        endTrigger: 'html',
        end: 'bottom bottom',
        pin: true,
        pinSpacing: false
    });

    $(document).on('click', '.js-scroll-section', function (e) {
        e.preventDefault();

        const section = '.module-' + $(this).data('section-index');

        $('html, body').animate({
            scrollTop: $(section).offset().top - 100
        }, 800);
    });    
};