const navOverlay = () => {
    const navVideo = document.getElementById('js-nav-video');
    let tl;
    tl = gsap.timeline({paused: true});

    tl.addLabel('start');

    tl.to('.c-header__logo-container', { autoAlpha: 0, duration: 0.4 }, 'start');
    tl.to('.c-header__nav-col', { autoAlpha: 0, duration: 0.4 }, 'start');
    tl.to('.c-header', { borderColor: 'rgba(0,0,0,0)', duration: 0.4 }, 'start');

    tl.to( $('.c__hamburger-line').eq(1), { autoAlpha: 0, duration: 0.4 }, 'start');
    tl.to( $('.c__hamburger-line').eq(0), { 
        x: 0, y: 7, rotation: 45, ease: Power1.easeOut, duration: 0.4 
    }, 'start');
    tl.to( $('.c__hamburger-line').eq(2), { 
        x: 0, y: -7, rotation: -45, ease: Power1.easeOut, duration: 0.4 
    }, 'start');
    tl.to( $('.c__hamburger-line').eq(4), { autoAlpha: 0, duration: 0.4 }, 'start');
    tl.to( $('.c__hamburger-line').eq(3), { 
        x: 0, y: 7, rotation: 45, ease: Power1.easeOut, duration: 0.4 
    }, 'start');
    tl.to( $('.c__hamburger-line').eq(5), { 
        x: 0, y: -7, rotation: -45, ease: Power1.easeOut, duration: 0.4 
    }, 'start');    

    tl.to('.c-header', { 
        backgroundColor: "rgba(119, 40, 35, 0)", boxShadow: "0 3px 15px rgb(0 0 0 / 0%)", duration: 0.4 
    }, "start");

    tl.to('.nav-overlay', { autoAlpha: 1, ease: Power1.easeOut, duration: 0.5 }, '<0.25');
    tl.to('.nav-overlay__bg', { top: 0, ease: Power1.easeOut, duration: 0.5 }, '<0.25');

    tl.to( $('.c-header__button-container'), {
        autoAlpha: 1, ease: Power1.easeOut, duration: 0.4 
    }, '>-0.1');

    tl.from( $('.nav-overlay__nav .menu-item'), {
        autoAlpha: 0, y: 50, ease: Power1.easeOut, duration: 0.4, stagger: 0.1 
    }, '>-0.25');
    tl.from( $('.nav-overlay__nav .menu-item .slash'), {
        autoAlpha: 0, y: 50, ease: Power1.easeOut, duration: 0.4, stagger: 0.1 
    }, '<0.2');

    $(document).on('click', '.js__hamburger', function(e) {
        e.preventDefault();

        if ( navVideo.paused ) {
            navVideo.play();
        }
        
        $('body').toggleClass('nav-open');

        if ( $(this).hasClass('active') ) {
            $(this).removeClass('active');
            tl.reverse();
        } else {
            $(this).addClass('active');
            tl.play();
        }
    });
};