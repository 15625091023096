$(document).ready(function(){
	animations();
	header();
	navOverlay();
	sliderInits();
	menus();
	gallery();
	contactForm();
	mailchimp();
	initMap();
	popup();
	sevenrooms();
});