function ZoomControl(controlDiv, map) {
    google.maps.event.addDomListener(zoomout, 'click', function() {
        var currentZoomLevel = map.getZoom();
        if (currentZoomLevel != 0) {
            map.setZoom(currentZoomLevel - 1);
        }
    });

    google.maps.event.addDomListener(zoomin, 'click', function() {
        var currentZoomLevel = map.getZoom();
        if(currentZoomLevel != 21){
            map.setZoom(currentZoomLevel + 1);
        }
    });
}

const initMap = () => {
    if ( $('#map').length > 0 ) {
        const myLatLng = { lat: 51.5129952, lng: -0.0752511 };
        const styles = getMapStyles();
    
        const map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 17,
            disableDefaultUI: true,
            styles: styles
        });
    
        const icon = '/wp-content/themes/florattica/library/images/icons/map_icon.png';
    
        new google.maps.Marker({
            position: myLatLng,
            map,
            icon: icon
        });    
    
        let customZoomDiv = document.createElement('div');
        let customZoom = new ZoomControl(customZoomDiv, map);
    }
};

const getMapStyles = () => {
    const mapStyles = [
        {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#fff4f3"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                },
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ];

    return mapStyles;
};