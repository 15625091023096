const sliderInits = () => {
    if ( $('.js__image-slider').length ) {
        const nextArrow = '<svg xmlns="http://www.w3.org/2000/svg" width="9.177" height="16.372" viewBox="0 0 9.177 16.372"><path d="M-587.562,780.2l8.11,7.826-8.11,7.826" transform="translate(587.91 -779.843)" fill="none" stroke="#fff" stroke-width="1"/></svg>';
        const prevArrow = '<svg xmlns="http://www.w3.org/2000/svg" width="9.177" height="16.372" viewBox="0 0 9.177 16.372"><path d="M-587.562,780.2l8.11,7.826-8.11,7.826" transform="translate(-578.733 796.216) rotate(180)" fill="none" stroke="#fff" stroke-width="1"/></svg>';

        let i = 1; 
        
        $('.js__image-slider').each(function(){
            $(this).addClass('js__image-slider-'+i);

            $('.js__image-slider-'+i).slick({
                arrows: true,
                dots: true,
                nextArrow: "<div class='slick-arrow slick-next'>"+nextArrow+"</div>",
                prevArrow: "<div class='slick-arrow slick-prev'>"+prevArrow+"</div>",
                mobileFirst: true,
                autoplay: true,
                useTransform: true,
                lazyLoad: 'ondemand',
                cssEase: 'cubic-bezier(.61,.43,0,.99)',
                speed: 1250,
                responsive: [{
                    breakpoint: 991,
                    settings: {
                        arrows: false
                    }
                }]
            });

            i++;
        });
    }

    if ( $('.js__ig-feed-slider').length ) {
        const nextArrow = '<svg xmlns="http://www.w3.org/2000/svg" width="21.038" height="16.949" viewBox="0 0 21.038 16.949"><g transform="translate(-202.829 -685.173)"><path d="M-587.562,780.2l8.2,8.119-8.2,8.119" transform="translate(802.518 -94.675)" fill="none" stroke="#f2dfc9" stroke-width="1"/><path d="M-1673,747.5h20.327" transform="translate(1875.83 -53.723)" fill="none" stroke="#f2dfc9" stroke-width="1"/></g></svg>';
        const nextArrowClass = 'slick-arrow slick-next | d-flex align-items-center justify-content-center';

        let i = 1; 
        
        $('.js__ig-feed-slider').each(function(){
            $(this).addClass('js__ig-feed-slider-'+i);

            $('.js__ig-feed-slider-'+i).slick({
                arrows: true,
                dots: false,
                nextArrow: "<div class='"+nextArrowClass+"'>"+nextArrow+"</div>",
                mobileFirst: true,
                responsive: [{
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4
                    }
                }]
            });

            i++;
        });
    }
};